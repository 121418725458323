<template>
  <v-container fluid>
    <v-data-table
      show-select
      v-model="selected"
      :headers="headers"
      :items="inventory_filter"
      :items-per-page="10"
      item-key="ean"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      :search="search"
      dense
    >
      <template #top>
        <v-bottom-navigation dark color="secondary">
          <v-btn>
            <span class="hidden-sm-and-down">Imprimir Etiquetas</span>
            <v-icon left> mdi-printer </v-icon>
          </v-btn>
          <v-btn @click="edit_price = !edit_price">
            <span class="hidden-sm-and-down">Cambio de Precio</span>
            <v-icon left> mdi-currency-usd</v-icon>
          </v-btn>
          <v-btn @click="edit_discount = !edit_discount">
            <span class="hidden-sm-and-down">Aplicar descuento</span>
            <v-icon left> mdi-sale </v-icon>
          </v-btn>
          <v-btn @click="price_changes_lst = !price_changes_lst">
            <span class="hidden-sm-and-down">Cambios de precio</span>
            <span class="hidden-sm-and-down">Reporte</span>
            <v-icon left> mdi-sale </v-icon>
          </v-btn>
          <v-btn @click="exportJSONToCSV(inventory_filter)">
            <span class="hidden-sm-and-down">Descargar</span>
            <v-icon left> mdi-download</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <v-toolbar flat>
          <h1>Inventario</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-select
                v-model="filters.categoria"
                :items="categories"
                label="Categoría"
                item-value="valor"
                item-text="valor"
                outlined
                @change="filterData"
                clearable
              ></v-select>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.marca"
                :items="brands"
                label="Marca"
                item-value="valor"
                item-text="valor"
                outlined
                clearable
                @change="filterData"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                v-model="filters.refe"
                label="Referencia"
                clearable
                outlined
                @keyup="filterData"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="filters.precio"
                label="Precio"
                clearable
                outlined
                @keyup="filterData"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>

    <template>
      <v-dialog v-model="item_dialogo" max-width="600px">
        <v-card>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card flat>
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="getImageURL(itemEdit.marca, itemEdit.refe)"
                  max-height="300"
                >
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile(itemEdit)"
                />
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-btn @click="chooseImage">Upload</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="edit_price" max-width="400px">
      <v-form v-model="price_valid" ref="priceChangeform" @submit="price_change">
        <v-card>
          <v-toolbar dark color="secondary" flat dense>
            <h3>Cambio de precio</h3>
            <v-spacer></v-spacer>
            <v-btn x-small fab color="orange" @click="edit_price = !edit_price">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6" v-if="selected.length === 0">
            <h5>Debe seleccionar los productos a los que quiere cambiar el precio</h5>
          </v-card-text>
          <v-card-text class="pt-6" v-if="selected.length !== 0">
            <h3>El nuevo precio será aplicado a {{ selected.length }} productos.</h3>
          </v-card-text>
          <v-card-text>
            <v-text-field
              mt="6"
              v-model="newPrice"
              class="centered-input"
              label="Nuevo precio"
              solo
              prepend-inner-icon="mdi-currency-usd"
              clearable
              :rules="f_required"
            />
          </v-card-text>
          <v-card-actions class="pt-6" v-if="selected.length !== 0">
            <v-spacer></v-spacer>
            <v-btn rounded color="warning" type="submit" :disabled="!price_valid">
              Aplicar cambio
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="edit_discount" max-width="400px">
      <v-form v-model="discount_valid" ref="discountChangeform" @submit="discount_change">
        <v-card>
          <v-toolbar dark color="secondary" flat dense>
            <h3>Descuento</h3>
            <v-spacer></v-spacer>
            <v-btn x-small fab color="orange" @click="edit_discount = !edit_discount">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6" v-if="selected.length === 0">
            <h5>Debe seleccionar los productos a los que quiere cambiar el precio</h5>
          </v-card-text>
          <v-card-text class="pt-6" v-if="selected.length !== 0">
            <h3>El descuento será aplicado a {{ selected.length }} productos.</h3>
          </v-card-text>
          <v-card-text>
            <v-text-field
              mt="6"
              v-model="newPrice"
              class="centered-input"
              label="Descuento"
              solo
              prepend-inner-icon="mdi-currency-usd"
              clearable
              :rules="f_required"
            />
          </v-card-text>
          <v-card-actions class="pt-6" v-if="selected.length !== 0">
            <v-spacer></v-spacer>
            <v-btn rounded color="warning" type="submit" :disabled="!discount_valid">
              Aplicar descuento
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="price_changes_lst"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          <v-toolbar flat>
            <h3>Cambios de precio efectuados</h3>
            <v-spacer></v-spacer>
            <v-btn
              x-small
              fab
              color="orange"
              @click="price_changes_lst = !price_changes_lst"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header_changes"
            :items="changes_lst"
            :items-per-page="10"
            class="elevation-4"
            :loading="loading_changes"
            loader-height="10"
            loading-text="Cargando ..."
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import createItem from "../utils/createItem.js";

export default {
  components: {},
  data() {
    return {
      errors: [],
      valid: false,
      item_dialogo: false,
      edit_price: false,
      edit_discount: false,
      price_valid: false,
      discount_valid: false,
      newPrice: 0,
      loading_status: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      imageUrl: null,
      dialog: false,
      headers: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          filterable: true,
          value: "categoria",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          filterable: true,
          value: "grupo",
          dataType: "text",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          filterable: true,
          value: "marca",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          filterable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          filterable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          filterable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          filterable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          filterable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Existencia",
          align: "end",
          sortable: true,
          filterable: true,
          value: "cantidad",
          dataType: "number",
        },
      ],
      header_changes: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          filterable: true,
          value: "categoria",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          filterable: true,
          value: "grupo",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          filterable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          filterable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "center",
          sortable: true,
          filterable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          filterable: true,
          value: "quantity",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "center",
          sortable: true,
          filterable: true,
          value: "price",
          dataType: "text",
        },
        {
          text: "Nuevo Precio",
          align: "center",
          sortable: true,
          filterable: true,
          value: "newPrice",
          dataType: "text",
        },
        {
          text: "Diferencia",
          align: "center",
          sortable: true,
          filterable: true,
          value: "diferencia",
          dataType: "text",
        },
      ],
      changes_lst: [],
      price_changes_lst: false,
      loading_changes: false,
      inventory_lst: [],
      inventory_filter: [],
      item: createItem(),
      selected: [],
      brands: [],
      categories: [],
      filters: {
        categoria: null,
        brand: null,
        precio: null,
      },
      search: "",
      itemEdit: createItem(),
      compressed_image: null,
    };
  },
  mounted() {
    this.get_inventory();
    this.categories = window.settings["CATEGORIA"].sort(this.GetSortOrder("valor"));
    this.brands = window.settings["MARCA"].sort(this.GetSortOrder("valor"));
  },
  methods: {
    get_inventory() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        table: "items",
      };
      qry.filters = [
        {
          field: "id_pos",
          operator: "=",
          value: window.store.store_id,
        },
        {
          field: "cantidad",
          operator: ">",
          value: 0,
        },
      ];

      webserver("get_table", qry, (data) => {
        console.log(data);
        // data.forEach((itm) => {
        //   itm.category = window.settings["CATEGORIA"].find(
        //     (item) => item.codigo == itm.categoria
        //   ).valor;
        //   itm.groupName = window.settings["GRUPO"].find(
        //     (item) => item.codigo == itm.grupo
        //   ).valor;
        //   itm.brand = window.settings["MARCA"].find(
        //     (item) => item.codigo == itm.marca
        //   ).valor;
        //   itm.colorName = window.settings["COLOR"].find(
        //     (item) => item.codigo == itm.color
        //   ).valor;
        // });
        this.loading_status = false;
        this.inventory_lst = data;
        this.inventory_filter = data;
      });
    },
    get_changes() {
      this.loading_changes = true;
      var qry = {
        store: window.store.store_id,
        issueDate: getToday(),
      };
      webserver("get_inventory_pc", qry, (data) => {
        console.log(data);
        data.forEach((itm) => {
          itm.diferencia = parseFloat(itm.diferencia);
          itm.price = parseFloat(itm.price);
          itm.quantity = parseFloat(itm.quantity);
          itm.newPrice = parseFloat(itm.newPrice);
        });
        this.loading_changes = false;
        this.changes_lst = data;
      });
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    getImageURL: function (marca, refe) {
      var account = window.store.store_id.substring(0, 6);
      var url =
        "https://moda-stc.com/photos/" + account + "/" + marca + "/" + refe + ".jpg";
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile(dat) {
      console.log(dat);
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            dat.picture = files[0].name;
            this.upload_photo(dat.marca, dat.refe.toLowerCase() + ".jpg", imageUrl);
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();

        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    upload_photo(marca, picture, data) {
      var qry = {
        store: window.store.store_id,
        photo: data,
        marca: marca,
        picture: picture,
      };

      webserver("upload_photo", qry, (data) => {
        console.log(data);
        this.compressed_image = null;
        //this.loading_status = false;
      });
    },
    filterData() {
      var filter = this.filters;
      this.inventory_filter = this.inventory_lst.filter(function (item) {
        for (var key in filter) {
          if (filter[key] !== null && filter[key] !== "") {
            // Usar includes para búsqueda parcial
            if (
              item[key] === undefined ||
              !item[key]
                .toString()
                .toLowerCase()
                .includes(filter[key].toString().toLowerCase())
            ) {
              return false;
            }
          }
        }
        return true;
      });
    },

    editItem(data) {
      this.itemEdit = data;
      //console.log(data);
      this.item_dialogo = true;
    },
    checkFile(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var img = new Image();
        img.onload = function () {
          var prct = 800 / img.width;
          const elem = document.createElement("canvas");
          elem.width = img.width * prct;
          elem.height = img.height * prct;
          const ctx = elem.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
          var imageUrl = elem.toDataURL("image/jpeg");
          console.log(imageUrl);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    newForm() {
      console.log(this.selected);
      //   this.supplier = createItem();
      //   this.valid = false;
      //   this.dialog = true;
    },
    exportJSONToCSV(objArray) {
      var arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);
      var element = document.createElement("a");
      element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
      element.target = "_blank";
      var current = new Date();
      var cDate =
        current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate();
      var cTime =
        current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      var fileName = cDate + "_" + cTime;
      element.download = fileName + ".csv";
      element.click();
    },
    discount_change(e) {
      e.preventDefault();
      this.$refs.discountChangeform.validate();
      if (this.discount_valid) {
        var qry = {
          store: window.store.store_id,
          newprice: parseInt(this.newPrice),
          products: this.selected,
          userId: window.userId,
        };
        if (qry.newprice > 0) {
          console.log(qry);
          webserver("changeDiscount", qry, () => {
            this.get_inventory();
            this.edit_discount = false;
            //this.price_changes_lst = true;
          });
        }
      }
    },
    price_change(e) {
      e.preventDefault();
      this.$refs.priceChangeform.validate();
      if (this.price_valid) {
        var qry = {
          store: window.store.store_id,
          newprice: parseInt(this.newPrice),
          products: this.selected,
          userId: window.userId,
        };
        if (qry.newprice > 0) {
          console.log(qry);
          webserver("changePrice", qry, () => {
            this.get_inventory();
            this.edit_price = false;
            //this.price_changes_lst = true;
          });
        }
      }
    },
  },
  watch: {
    price_changes_lst(visible) {
      if (visible) {
        console.log(visible);
        this.get_changes();
        //this.setSku();
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}

.centered-input input {
  text-align: center;
}
</style>
